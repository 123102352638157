import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPopularProducts, getProductDetails, sendUserData } from '../services/product';
import { toast } from 'react-toastify';
import { checkProductExists } from '../utils/cartUtils';
import loading from "../assets/Images/loading.gif";
import { useCart } from '../contexts/CartContext';
import CustomLoadingCircularImage from '../components/CircularImage';
import ProductCard from '../components/ProductCard';
import { CustomerReviews } from '../components/CustomerReviews';
import ProductDetailsTabView from '../components/ProductDetailsTabView';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ImageZoom from '../components/ImageZoom/ImageZoom';
import image404 from "../assets/Navbar/notfount.webp";
import ProductImages from '../components/ProductDetails/ProductImages';
import { Helmet } from 'react-helmet';
import { getUserToken, saveUserToken } from '../utils/loginUtils';


const ProductDetailedPage = () => {
    const isTab = useMediaQuery('(max-width: 966px)');
    const { t } = useTranslation();
    const { slug } = useParams();
    const [productData, setProductData] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [isProductAdded, setIsProductAdded] = useState(false);
    const [customerReviews, setCustomReviews] = useState([]);
    const [productFound, setProductFound] = useState(true);
    const [popularProducts, setPopularProducts] = useState([]);
    const { addToCart } = useCart();
    const host = window.location.href;


    useEffect(() => {
        const fetchProductData = async () => {
            try {
                setProductData(null);
                const response = await getProductDetails(slug);
                setProductData(response.data);
                if (response.data.product_images.length > 0) {
                    setSelectedImage(response.data.product_images[0].image);
                } else {
                    setSelectedImage('');
                }
                setProductFound(true);
                if (getUserToken().length !== 0) {
                    await sendUserData(response.data.id);
                }
            } catch (error) {
                setProductFound(false);
                const popProds = await getPopularProducts();
                setPopularProducts(popProds.data.results);
            }
        };

        fetchProductData();
    }, [slug]);

    useEffect(() => {
        checkProduct();
    }, [productData]); // Check whenever product data changes

    const checkProduct = () => {
        if (productData) {
            const isTrue = checkProductExists(productData);
            setIsProductAdded(isTrue);
        }
    };

    const handleAddToCart = () => {
        addToCart(productData);
        checkProduct(); // Update the cart status
    };

    const handleBuyNow = () => {
        addToCart(productData);
    };

    const handleWhatsapp = () => {
        window.open(`https://wa.me/+918602793619?text=Hi, I am interested in buying this product ${window.location.href}`, "_blank");
    };

    const handleShare = async () => {
        const shareData = {
            title: 'RashailAgro',
            text: 'Hey!, checkout the product on RashailAgro Bazaar',
            url: window.location.href
        };

        if (navigator.share) {
            try {
                await navigator.share(shareData);
            } catch (err) {
                console.error('Error sharing the product:', err);
            }
        } else {
            toast("Web Share API not supported. Please use the copy link or other options.");
        }
    };

    useEffect(
        () => {
            if (getUserToken().length === 0) {
                const searchParam = window.location.search;
                if (searchParam.includes("token")) {
                    const token = searchParam.replaceAll("?token=", "");
                    saveUserToken(token);
                }
            }
        }, []);

    return (
        <>
            <Helmet>
                <title>{
                    productData ?
                        productData.product_name :
                        "Fasalam Bazaar - Your One-Stop Agro Machinery Market"
                }
                </title>
                <meta
                    name="description"
                    content="Explore Fasalam Bazaar for a wide range of high-quality agro products at unbeatable prices. Power Tools, Garden Equipment, Agricultural Machinery, Hand Tools, Spray Pump, Sprayers, Zatka Machine, Irrigation Machinery, Seeders and more. Enjoy Cash on Delivery and Fast Delivery services."
                />
                <meta
                    name="keywords"
                    content="Fasalam Bazaar, Agro Products, Agricultural Machinery, Garden Equipment, Power Tools, Hand Tools, Spray Pump, Sprayers, Zatka Machine, Irrigation Machinery, Seeders, Agricultural Tools, Farm Machinery, Garden Tools, Buy Farm Equipment Online, Agro Marketplace, Agro Supplies Online"
                />
                <meta name="author" content="Rashail Agro" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                {/* Open Graph Tags for Social Sharing */}
                <meta
                    property="og:title"
                    content="Fasalam Bazaar - Your One-Stop Agro Machinery Market"
                />
                <meta
                    property="og:description"
                    content="Explore Fasalam Bazaar for a wide range of high-quality agro products at unbeatable prices. Power Tools, Garden Equipment, Agricultural Machinery, Hand Tools, Spray Pump, Sprayers, Zatka Machine, Irrigation Machinery, Seeders and more. Enjoy Cash on Delivery and Fast Delivery services."
                />
                <meta property="og:image" content={`${host}/og-image.jpg`} />
                <meta property="og:url" content={`${host}`} />
                <meta property="og:type" content="website" />
                {/* Twitter Card Tags */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Fasalam Bazaar" />
                <meta
                    property="twitter:description"
                    content="Your trusted agro machinery marketplace."
                />
                <meta property="twitter:image" content={`${host}/twitter-image.jpg`} />
                {/* Canonical Link */}
                <link rel="canonical" href={`${host}`} />
                {/* Structured Data (JSON-LD) */}
                <script type="application/ld+json">
                    {`
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Fasalam Bazaar",
                "url": "${host}",
                "logo": "${host}/logo512.png",
                "sameAs": [
                    "https://www.facebook.com/rashailagro/",
                    "https://www.instagram.com/rashailagro/"
                ]
            }
        `}
                </script>
                <script type="application/ld+json">
                    {`
            {
                "@context": "https://schema.org",
                "@type": "Product",
                "name": "${productData ?
                            productData.product_name : ""
                        }",
                "image": "${selectedImage.length !== 0 ?
                            selectedImage : ""}",
                "description": "${productData ?
                            productData.description : ""}",
                "brand": "Rashail Agro",
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "INR",
                    "price": "${productData ? productData.price_string : ""}",
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": "https://schema.org/InStock"
                }
            }
        `}
                </script>
                {selectedImage.length !== 0 && (
                    <link rel="icon" type="image/x-icon" href={selectedImage} />
                )}
            </Helmet>

            <div className="mt-32 bg-gray-50">
                {
                    productFound ? (
                        productData ? (
                            <div className="p-4 md:p-8">
                                <div className={`grid grid-cols-1 md:grid-cols-1 ${productData.availability_status !== "OUT_OF_STOCK" && productData.availability_status !== "DISCONTINUED" ? "lg:grid-cols-3" : "lg:grid-cols-2"} gap-2 mb-4`}>
                                    {/* Container 1 */}
                                    <div className="flex flex-col shadow-lg p-4 rounded-lg border bg-white relative">
                                        <ProductImages isTab={isTab} productData={productData} />
                                        <div className={`text-sm text-white font-semibold absolute top-2 left-2 ${productData.availability_status === 'IN_STOCK' ? 'bg-primary' : 'bg-red-600'} rounded-lg px-4 py-1`}>
                                            {productData.availability_status.replace("_", " ")}
                                        </div>
                                        <div className={`font-semibold absolute top-2 right-2 cursor-pointer`}>
                                            <svg fill='green' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-6' onClick={handleShare}>
                                                <path d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z" />
                                            </svg>
                                        </div>
                                        {/* <button
                                        onClick={handleWhatsapp}
                                        className="w-full gap-2 items-center bg-green-500 text-white py-4 px-4 text-lg fill-white rounded hover:bg-green-700 transition-colors font-bold flex flex-row justify-center"
                                    >
                                        Get bulk quote now
                                    </button> */}
                                        {
                                            productData.availability_status !== "OUT_OF_STOCK" && productData.availability_status !== "DISCONTINUED" ?

                                                <Link to='/cart'>
                                                    <button
                                                        onClick={handleBuyNow}
                                                        className="w-full gap-2 items-center bg-green-500 text-white py-4 px-4 text-xl fill-white rounded hover:bg-green-700 transition-colors font-bold flex flex-row justify-center"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='h-5'>
                                                            <path d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                                        </svg>
                                                        {t("buyNow")}
                                                    </button>
                                                </Link > : <></>
                                        }
                                    </div>

                                    {/* Container 2 */}
                                    <div className="flex flex-col shadow-lg p-4 rounded-lg border bg-white">
                                        <h1 className="text-xl font-bold mb-1">{productData.product_name}</h1>
                                        <Link to={`/shop/partners/${productData.manufacturer_slug}`} className={`text-primary text-xs hover:underline mb-4`}>
                                            {productData.manufacturer}
                                        </Link>
                                        <ul>
                                            {(productData.product_details.FEATURES || []).map((feature, index) => (
                                                <li key={index} className={`flex items-center mb-2 text-md feature-list-item`}>
                                                    <span className="checkmark mr-2 bg-primary rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path
                                                                d="M5 10l3 3L15 7"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </span>
                                                    {feature.value}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {/* Container 3 */}
                                    {
                                        productData.availability_status !== "OUT_OF_STOCK" && productData.availability_status !== "DISCONTINUED" ?

                                            <div className="flex flex-col text-center shadow-lg p-4 rounded-lg border bg-white">
                                                <div className="flex flex-col justify-between mb-2 md:flex-row lg:flex-row">
                                                    {
                                                        productData.shop_sku != null ?
                                                            (<span>
                                                                <span className='font-bold mr-2'>
                                                                    SKU:-
                                                                </span>
                                                                <span>
                                                                    {productData.shop_sku}
                                                                </span>
                                                            </span>) : (<></>)
                                                    }
                                                    {productData.model_number != null ? (<span>
                                                        <span className='font-bold mr-2'>
                                                            Model No.:-
                                                        </span>
                                                        <span>
                                                            {productData.model_number}
                                                        </span>
                                                    </span>) : (<></>)
                                                    }
                                                </div>
                                                <div className="flex items-center space-x-4 justify-center">
                                                    <span className="text-2xl font-bold text-primary">₹{productData.price_string}</span>
                                                    <span className="text-xl line-through text-gray-500">₹{parseInt(productData.mrp) || productData.mrp}</span>
                                                </div>
                                                <span className="text-sm text-slate-500">Inclusive of all taxes</span>
                                                <div className="flex flex-row justify-center my-2 gap-2">
                                                    <div className="bg-red-500 rounded-full px-4 py-2 font-bold text-white text-sm">
                                                        {parseInt(productData.discount_percentage)}%  OFF
                                                    </div>
                                                    <span className='text-primary text-lg font-bold'>
                                                        You saved ₹{parseInt(productData.mrp - productData.price) || (productData.mrp - productData.price)}
                                                    </span>
                                                </div>
                                                <div className="flex flex-row justify-center item-center gap-4 mt-2 fill-primary">
                                                    <svg height={30} width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                        <path d="M48 0C21.5 0 0 21.5 0 48L0 368c0 26.5 21.5 48 48 48l16 0c0 53 43 96 96 96s96-43 96-96l128 0c0 53 43 96 96 96s96-43 96-96l32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64 0-32 0-18.7c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7L416 96l0-48c0-26.5-21.5-48-48-48L48 0zM416 160l50.7 0L544 237.3l0 18.7-128 0 0-96zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                                    </svg>
                                                    <span className='text-md font-bold text-start'>Get fastest delivery by 5-7 days</span>
                                                </div>
                                                <div className="overflow-hidden transition-height duration-300 px-14 mt-6">
                                                    <div className='flex overflow-x-auto space-x-9 ml-4 mb-4'>
                                                        {productData.product_features.map((e) => <div
                                                            key={e.id}
                                                            className="flex flex-col items-center cursor-pointer"
                                                        >
                                                            <div className="relative w-10 h-10">
                                                                <div className="rounded-full overflow-hidden border-2 border-primary">
                                                                    <CustomLoadingCircularImage imageUrl={e.icon || ""} size={12} />
                                                                </div>
                                                            </div>
                                                            <span className="text-sm text-center">{e.name}</span>
                                                        </div>)}
                                                    </div>
                                                </div>
                                                {
                                                    productData.availability_status !== "OUT_OF_STOCK" && productData.availability_status !== "DISCONTINUED" ?

                                                        <div className='grid grid-cols-1 gap-2'>
                                                            <Link to='/cart'>
                                                                <button
                                                                    onClick={handleBuyNow}
                                                                    className="w-full gap-2 items-center bg-green-500 text-white py-4 px-4 text-xl fill-white rounded hover:bg-green-700 transition-colors font-bold flex flex-row justify-center breathing-animation"
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='h-5'>
                                                                        <path d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                                                    </svg>
                                                                    {t("buyNow")}
                                                                </button>
                                                            </Link>
                                                            {!isProductAdded ? (
                                                                <button
                                                                    onClick={handleAddToCart}
                                                                    className="w-full border border-green-400 bg-red-500 border-solid text-white py-4 px-4 text-xl font-bold rounded hover:bg-red-700 fill-white transition-colors gap-2 items-center flex flex-row justify-center"
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='h-5'>
                                                                        <path d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48l45.5 0c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5L488 384c13.3 0 24-10.7 24-24s-10.7-24-24-24l-288.3 0c-11.5 0-21.4-8.2-23.6-19.5L170.7 288l288.5 0c32.6 0 61.1-21.8 69.5-53.3l41-152.3C576.6 57 557.4 32 531.1 32L360 32l0 102.1 23-23c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-64 64c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l23 23L312 32 120.1 32C111 12.8 91.6 0 69.5 0L24 0zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm336-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z" />
                                                                    </svg>
                                                                    {t("addToCart")}
                                                                </button>
                                                            ) : (
                                                                <Link to='/cart' className='w-full'>
                                                                    <button
                                                                        className="w-full bg-red-500 text-white py-4 px-4 text-xl rounded hover:bg-red-700 transition-colors font-bold gap-2 items-center flex flex-row justify-center"
                                                                    >
                                                                        <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='h-5'>
                                                                            <path d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                                                        </svg>
                                                                        {t("goToCart")}
                                                                    </button>
                                                                </Link>
                                                            )}
                                                            <button
                                                                onClick={handleWhatsapp}
                                                                className="w-full border border-primary border-solid text-primary py-4 px-4 text-lg font-bold rounded hover:bg-primary hover:text-white hover:fill-white fill-primary transition-colors gap-2 items-center flex flex-row justify-center"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-5'>
                                                                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                                                                </svg>
                                                                {t("orderOnWhatsapp")}
                                                            </button>
                                                        </div> : <>
                                                            <button
                                                                className="w-full gap-2 items-center bg-red-700 text-white py-4 px-4 text-xl fill-white rounded  transition-colors font-bold flex flex-row justify-center cursor-not-allowed"
                                                                disabled
                                                            >
                                                                {productData.availability_status.replace("_", " ")}
                                                            </button></>
                                                }
                                            </div> : <></>
                                    }
                                </div>
                                <p className='text-justify my-2 mt-4'>
                                    {productData.description}
                                </p>
                                <ProductDetailsTabView productDetails={productData.product_details} />
                                {
                                    productData.related_products.length !== 0 ?
                                        <div className="flex flex-row justify-between mt-4 px-6">
                                            <p className='text-center text-xl my-4 font-bold'>{t("similarProducts")}</p>
                                        </div> : <></>
                                }
                                <div className="flex overflow-x-auto space-x-4 ml-4 mb-4 pb-4">
                                    {productData.related_products.map((product) => (
                                        productData.related_products.length > 1 ?
                                            <ProductCard key={product.id} product={product} /> :
                                            <div className="min-w-32">
                                                <ProductCard key={product.id} product={product} />
                                            </div>
                                    ))}
                                </div>
                                {
                                    productData.related_products.length !== 0 ?
                                        <div className="flex flex-row justify-between mt-4 px-6">
                                            <p className='text-center text-xl my-4 font-bold'>{t("bestSellers")}</p>
                                        </div> : <></>
                                }
                                <div className="flex overflow-x-auto space-x-4 ml-4 mb-4 pb-4">
                                    {productData.related_products.map((product) => (
                                        productData.related_products.length > 1 ?
                                            <ProductCard key={product.id} product={product} /> :
                                            <div className="min-w-32">
                                                <ProductCard key={product.id} product={product} />
                                            </div>
                                    ))}
                                </div>
                                <CustomerReviews reviews={customerReviews} />
                            </div >
                        ) : (
                            <div className="h-full w-full flex justify-center">
                                <img src={loading} alt="Loading..." className='min-h-40' />
                            </div>
                        )
                    ) : (<div className="container mx-auto mt-32 flex flex-col justify-center items-center">
                        <img src={image404} alt="404 Page Not Found" />
                        <span className='font-bold text-md text-center sm:text-xl md:text-4xl mb-10 text-primary'>
                            {t("looksLikeThisProductIsInvalid")}
                        </span>
                        {popularProducts.length !== 0 ?
                            (<div className="flex flex-row justify-start px-4 md:px-14 items-start w-full">
                                <p className='text-start text-2xl my-4 font-bold'>{t("keepShopingFor")}</p>
                            </div>) : (<></>)}
                        {
                            popularProducts.length !== 0 ?
                                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 px-4 md:px-14">
                                    {popularProducts.map((product) => (
                                        <ProductCard key={product.id} product={product} />
                                    ))}
                                </div> : <></>
                        }
                    </div>)
                }
            </div >
        </>
    );
};

export default ProductDetailedPage;
