import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./locals/en/translation.json";
import translationsHi from "./locals/hi/translation.json";
import translationsMr from "./locals/mr/translation.json";
import translationsGu from "./locals/gu/translation.json";

// Retrieve the saved language from localStorage, or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: translationsEn,
            },
            hi: {
                translation: translationsHi,
            },
            mr: {
                translation: translationsMr,
            },
            gu: {
                translation: translationsGu,
            },
        },
        lng: savedLanguage, // Use the saved language from localStorage
        fallbackLng: "en", // Fallback language
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
