import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Cart from './pages/Cart';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewAllProducts from './pages/ViewAllProducts';
import ScrollToTop from './utils/ScrollToTop';
import ProductDetailedPage from './pages/ProductDetailedPage';
import { CartProvider } from './contexts/CartContext';
import ReviewOrder from './pages/ReviewOrder';
import PageNotFound from './pages/PageNotFound';
import ShopDetailedPage from './pages/ShopDetailedPage';
import ViewAllShops from './pages/ViewAllShops';
import ViewAllCategories from './pages/ViewAllCategories';
import ViewAllSuppliers from './pages/ViewAllSuppliers';
import SellerDetailedPage from './pages/SellerDetailedPage';
import "./i18n";
import PaymentsPage from './pages/help/PaymentPage';
import ShippingPage from './pages/help/ShippingPage';
import CancellationReturnsPage from './pages/help/CancellationReturnsPage';
import FAQPage from './pages/help/FAQPage';
import ConsumerPolicyPage from './pages/consumer_policies/ConsumerPolicyPage ';
import TermsOfUsePage from './pages/consumer_policies/TermsOfUsePage';
import PrivacyPolicyPage from './pages/consumer_policies/PrivacyPolicyPage';
import Sitemap from './pages/consumer_policies/SitemapSection';
import whatsappImage from "./assets/Footer/whatsapp.png";
import VendorRegistrationForm from './pages/vendor_registration_form/VendorRegistrationForm';

function App() {
  return (
    <CartProvider>
      <Router>
        <ScrollToTop />
        <Navbar />
        <div className='w-full'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/view/products" element={<ViewAllProducts />} />
            <Route path="/view/products/:slug/" element={<ProductDetailedPage />} />
            <Route path="/shop/partners/:slug/" element={<ShopDetailedPage />} />
            <Route path="/shop/seller/:slug/" element={<SellerDetailedPage />} />
            <Route path="/shop/" element={<ViewAllShops />} />
            <Route path="/shop/partners/" element={<ViewAllShops />} />
            <Route path="/shop/near-by-suppliers/" element={<ViewAllSuppliers />} />
            <Route path="/order" element={<ReviewOrder />} />
            <Route path="/categories" element={<ViewAllCategories />} />
            <Route path="/help/payments" element={<PaymentsPage />} />
            <Route path="/help/shipping" element={<ShippingPage />} />
            <Route path="/help/canellation-return" element={<CancellationReturnsPage />} />
            <Route path="/help/faqs" element={<FAQPage />} />
            <Route path="/consumer-policies" element={<ConsumerPolicyPage />} />
            <Route path="/consumer-policies/terms-of-use" element={<TermsOfUsePage />} />
            <Route path="/consumer-policies/privacy-policies" element={<PrivacyPolicyPage />} />
            <Route path="/consumer-policies/sitemap" element={<Sitemap />} />

            {/* Add the route for VendorRegistrationForm */}
            <Route path="/vendor-registration/*" element={<VendorRegistrationForm />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>

          <ToastContainer stacked={true} />

          <Link
            to="https://wa.me/+918349503619?text=%E0%A4%A8%E0%A4%AE%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%87%21%20%0A%E0%A4%AE%E0%A5%88%E0%A4%82%20%E0%A4%AB%E0%A4%BC%E0%A4%B8%E0%A4%B2%E0%A4%AE%20%E0%A4%AC%E0%A4%BE%E0%A4%9C%E0%A4%BC%E0%A4%BE%E0%A4%B0%20%E0%A4%AA%E0%A4%B0%20%E0%A4%86%E0%A4%AF%E0%A4%BE%20%E0%A4%B9%E0%A5%82%E0%A4%81%20%E0%A4%94%E0%A4%B0%20%E0%A4%86%E0%A4%AA%E0%A4%95%E0%A5%87%20%E0%A4%89%E0%A4%A4%E0%A5%8D%E0%A4%AA%E0%A4%BE%E0%A4%A6%E0%A5%8B%E0%A4%82%2F%E0%A4%B8%E0%A5%87%E0%A4%B5%E0%A4%BE%E0%A4%93%E0%A4%82%20%E0%A4%95%E0%A5%87%20%E0%A4%AC%E0%A4%BE%E0%A4%B0%E0%A5%87%E0%A4%82%20%E0%A4%85%E0%A4%A7%E0%A4%BF%E0%A4%95%20%E0%A4%9C%E0%A4%BE%E0%A4%A8%E0%A4%A8%E0%A4%BE%20%E0%A4%9A%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A4%BE%20%E0%A4%B9%E0%A5%82%E0%A4%81%E0%A5%A4%20%E0%A4%95%E0%A5%8D%E0%A4%AF%E0%A4%BE%20%E0%A4%86%E0%A4%AA%20%E0%A4%AE%E0%A5%81%E0%A4%9D%E0%A5%87%20%E0%A4%95%E0%A4%BF%E0%A4%B8%E0%A5%80%20%E0%A4%90%E0%A4%B8%E0%A5%87%20%E0%A4%B5%E0%A5%8D%E0%A4%AF%E0%A4%95%E0%A5%8D%E0%A4%A4%E0%A4%BF%20%E0%A4%B8%E0%A5%87%20%E0%A4%9C%E0%A5%8B%E0%A4%A1%E0%A4%BC%20%E0%A4%B8%E0%A4%95%E0%A5%87%20%E0%A4%B9%E0%A5%88%20%E0%A4%9C%E0%A5%8B%20%E0%A4%AE%E0%A5%87%E0%A4%B0%E0%A5%80%20%E0%A4%AA%E0%A5%82%E0%A4%9B%E0%A4%A4%E0%A4%BE%E0%A4%9B%20%E0%A4%AE%E0%A5%87%E0%A4%82%20%E0%A4%AE%E0%A4%A6%E0%A4%A6%20%E0%A4%95%E0%A4%B0%20%E0%A4%B8%E0%A4%95%E0%A5%87%3F%0A%E0%A4%A7%E0%A4%A8%E0%A5%8D%E0%A4%AF%E0%A4%B5%E0%A4%BE%E0%A4%A6%21%20"
            target="_blank"
          >
            <div className="fixed bottom-6 right-0 flex items-center justify-center cursor-pointer bg-transparent transition-colors breathing-animation">
              <img src={whatsappImage} alt="whatsapp" className="h-10 md:h-20 object-contain" style={{
                filter: "drop-shadow(2px 2px 2px #222)",
              }} />
            </div>
          </Link>
        </div>
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;
