import React from 'react';

const CancellationReturnsPage = () => {
    const policyPoints = [
        {
            title: "Cancellations",
            content: "Orders can be canceled within 24 hours of purchase through your account. Refunds for canceled orders are processed within 3-7 business days."
        },
        {
            title: "Returns and Replacements",
            content: "Return and replacement policies may vary by product, brand, or vendor. It's recommended to contact our customer care before making a purchase for specific return terms related to the brand."
        },
        {
            title: "Product Quality Disclaimer",
            content: "Rashail Agro Bazaar serves as an online platform. Product quality and service vary across associated vendors/brands. We do not take liability for brand quality or services."
        },
        {
            title: "Non-Returnable Items",
            content: "Certain products (e.g., consumables, custom items) are non-returnable. Product descriptions specify non-returnable status where applicable."
        },
        {
            title: "Refunds",
            content: "Refunds are initiated after we receive and inspect the returned item, typically processed within 5-10 business days."
        },
        {
            title: "Warranty & Guarantee",
            content: "For warranty or guarantee claims, please contact the respective brand or vendor directly. We can assist with your request but assume no responsibility for these claims."
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 mt-28 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Cancellation & Returns</h1>
                    <h2 className="text-2xl font-semibold text-gray-700 italic">
                        Easy Cancellations, Returns & Replacement Policies
                    </h2>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">
                            We understand that changes happen, and our cancellation, return, and replacement policies are designed to support you:
                        </h3>
                    </div>
                    <div className="space-y-6">
                        {policyPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                                <h3 className="font-semibold text-gray-800 mb-2">
                                    {point.title}
                                </h3>
                                <p className="text-gray-600">
                                    {point.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                    <span className="text-blue-800">
                        For questions about our cancellation and return policies, contact{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CancellationReturnsPage;