import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Carousel = ({ images, autoplay = true, autoplayInterval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalSlides = images.length;

    const handlePrev = () => {
        setCurrentIndex(currentIndex === 0 ? totalSlides - 1 : currentIndex - 1);
    };

    const handleNext = () => {
        setCurrentIndex(currentIndex === totalSlides - 1 ? 0 : currentIndex + 1);
    };

    // Autoplay effect
    useEffect(() => {
        if (autoplay) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex === totalSlides - 1 ? 0 : prevIndex + 1));
            }, autoplayInterval);

            // Cleanup the interval on component unmount
            return () => clearInterval(interval);
        }
    }, [autoplay, autoplayInterval, totalSlides]);

    if (images.length !== 0) {
        return (
            <div className="relative w-full overflow-hidden">
                {/* Carousel Images */}
                <div
                    className="flex transition-transform duration-500"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((image, index) => {
                        const isLinkValid = image.link && image.link !== ''; // Check if link exists and is non-empty

                        return (
                            <div key={index} className="w-full flex-shrink-0">
                                {isLinkValid ? (
                                    image.link.includes("http") ? (
                                        // For external links
                                        <img
                                            src={image.image}
                                            alt={`Slide ${index + 1}`}
                                            className="w-full h-auto object-cover cursor-pointer"
                                            onClick={() => window.open(image.link, "_blank")} // Open external link
                                        />
                                    ) : (
                                        // For internal links using Link component
                                        <Link to={image.link}>
                                            <img
                                                src={image.image}
                                                alt={`Slide ${index + 1}`}
                                                className="w-full h-auto object-cover cursor-pointer"
                                            />
                                        </Link>
                                    )
                                ) : (
                                    // If link is null or empty, render the image without any click functionality
                                    <img
                                        src={image.image}
                                        alt={`Slide ${index + 1}`}
                                        className="w-full h-auto object-cover" // No cursor-pointer since it's not clickable
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                {/* Navigation buttons */}
                <button
                    onClick={handlePrev}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white p-2 rounded-full"
                >
                    {"<"}
                </button>
                <button
                    onClick={handleNext}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white p-2 rounded-full"
                >
                    &gt;
                </button>

                {/* Dots Indicator */}
                <div className="absolute bottom-4 w-full flex justify-center space-x-2">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-primary' : 'bg-gray-300'
                                }`}
                            onClick={() => setCurrentIndex(index)}
                        />
                    ))}
                </div>
            </div>
        );
    } else {
        return null; // Return null or empty fragment if there are no images
    }
};

export default Carousel;
