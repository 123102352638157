import axios from "axios";
import { getUserToken } from "../utils/loginUtils";

export const callPagination = async (nextPageUrl) => {
    const token = getUserToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(nextPageUrl, config);
    return response;
};
