import React, { useState } from 'react';
import { ChevronDown, ChevronRight, Home, ShoppingBag, User, Shield, Headphones, Building2, Grid, Facebook, Instagram, Twitter, Linkedin } from 'lucide-react';

const SitemapSection = ({ title, items, icon: Icon }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="mb-2">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center w-full p-3 text-left bg-green-50 hover:bg-green-100 rounded-lg transition-colors duration-200"
            >
                <Icon className="w-5 h-5 text-green-600 mr-2" />
                <span className="text-lg font-semibold text-green-800">{title}</span>
                {isOpen ?
                    <ChevronDown className="w-5 h-5 ml-auto text-green-600" /> :
                    <ChevronRight className="w-5 h-5 ml-auto text-green-600" />
                }
            </button>

            {isOpen && (
                <div className="mt-2 ml-7 space-y-2">
                    {items.map((item, index) => (
                        <div key={index}>
                            {Array.isArray(item) ? (
                                <div className="ml-4 mt-2">
                                    {item.map((subItem, subIndex) => (
                                        <a
                                            key={subIndex}
                                            href={subItem.path}
                                            className="block py-1 text-gray-600 hover:text-green-600 transition-colors duration-200"
                                        >
                                            {subItem.label}
                                        </a>
                                    ))}
                                </div>
                            ) : (
                                <a
                                    href={item.path}
                                    className="block py-1 text-gray-600 hover:text-green-600 transition-colors duration-200"
                                >
                                    {item.label}
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const Sitemap = () => {
    const sections = [
        {
            title: 'Homepage',
            icon: Home,
            items: [{ path: '/', label: 'Home' }]
        },
        {
            title: 'Shop',
            icon: ShoppingBag,
            items: [
                { path: '/categories', label: 'All Categories' },
                { path: '/view/products', label: 'All Products' },
                { path: '/shop', label: 'All Shops' },
                { path: '/shop/partners', label: 'Shop Partners' },
                { path: '/shop/near-by-suppliers', label: 'Nearby Suppliers' }
            ]
        },
        {
            title: 'Account & Orders',
            icon: User,
            items: [
                { path: '/cart', label: 'Shopping Cart' },
                { path: '/order', label: 'Review Order' }
            ]
        },
        {
            title: 'Help Center',
            icon: Headphones,
            items: [
                { path: '/help/payments', label: 'Payments' },
                { path: '/help/shipping', label: 'Shipping' },
                { path: '/help/canellation-return', label: 'Cancellation & Returns' },
                { path: '/help/faqs', label: 'FAQs' }
            ]
        },
        {
            title: 'Policies',
            icon: Shield,
            items: [
                { path: '/consumer-policies', label: 'Consumer Policies' },
                { path: '/consumer-policies/terms-of-use', label: 'Terms of Use' },
                { path: '/consumer-policies/privacy-policies', label: 'Privacy Policy' },
                { path: '/consumer-policies/sitemap', label: 'Sitemap' }
            ]
        }
    ];

    const socialLinks = [
        { icon: Facebook, name: 'Facebook', url: '#' },
        { icon: Instagram, name: 'Instagram', url: '#' },
        { icon: Twitter, name: 'Twitter', url: '#' },
        { icon: Linkedin, name: 'LinkedIn', url: '#' }
    ];

    return (
        <div className="max-w-4xl mx-auto p-6 mt-28">
            <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-green-800 mb-2">Rashail Agro Bazaar Sitemap</h1>
                <p className="text-gray-600">Use this sitemap to navigate through our platform quickly</p>
            </div>

            <div className="grid gap-2 md:grid-cols-2">
                {sections.map((section, index) => (
                    <SitemapSection
                        key={index}
                        title={section.title}
                        items={section.items}
                        icon={section.icon}
                    />
                ))}
            </div>

            <div className="mt-8 pt-6 border-t border-gray-200">
                <h2 className="text-xl font-semibold text-green-800 mb-4">Connect With Us</h2>
                <div className="flex space-x-4">
                    {socialLinks.map((social, index) => (
                        <a
                            key={index}
                            href="#"
                            className="flex items-center p-2 text-gray-600 hover:text-green-600 transition-duration-200"
                        >
                            <social.icon className="w-5 h-5 mr-2" />
                            <span>{social.name}</span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Sitemap;