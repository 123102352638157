import React from 'react';
import image404 from "../assets/Navbar/notfount.webp";
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div className="container mx-auto mt-32 flex flex-col justify-center items-center">
            <img src={image404} alt="404 Page Not Found" />
            <span className='font-bold text-4xl mb-10 text-primary'>
                Page Not Found
            </span>
            <Link to="/">
                <button className="w-full bg-primary text-white py-2 px-4 rounded hover:bg-green-700 transition-colors" >
                    Go Back Home
                </button>
            </Link>
        </div>
    );
};

export default PageNotFound;
