import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import loading from "../assets/Images/loading.gif";
import { CustomerReviews } from '../components/CustomerReviews';
import { getProducts } from '../services/product';
import { toast } from 'react-toastify';
import { useMediaQuery } from '@mui/material';
import { getBrandDetails } from '../services/manufacturer';
import ProductCard from '../components/ProductCard';
import { callPagination } from '../services/pagination';
import LoadingSpinner from '../components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

const ShopDetailedPage = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const { slug } = useParams();
    const listEndRef = useRef(); // Element to observe at the end of the list
    const [shopData, setShopData] = useState({ partner: {} });
    const [shopProducts, setShopProducts] = useState([]);
    const [customerReviews, setCustomReviews] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchManufacturerData = async () => {
            try {
                const manufacturerResponse = await getBrandDetails(slug);
                setShopData({
                    partner: manufacturerResponse.data,
                });
                fetchProductData(manufacturerResponse.data.name);
            } catch (error) {
                toast("Failed to fetch manufacturer details");
            }
        };
        fetchManufacturerData();
    }, [slug]);

    const fetchProductData = async (name) => {
        setLoading(true);
        try {
            const response = await getProducts({ filter: `manufacturer=${name}` });
            setShopProducts(response.data.results);
            setNextPage(response.data.next); // Set the next page URL here
        } catch (error) {
            toast("Failed to fetch manufacturer products");
        } finally {
            setLoading(false);
        }
    };

    // Infinite scrolling logic with Intersection Observer
    useEffect(() => {
        if (!listEndRef.current || !nextPage || loading) return;

        const observer = new IntersectionObserver(
            async (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting && nextPage && !loading) {
                    await callMorePages();
                }
            },
            { rootMargin: '100px', threshold: 1.0 }
        );

        observer.observe(listEndRef.current);

        return () => {
            if (listEndRef.current) {
                observer.unobserve(listEndRef.current);
            }
        };
    }, [nextPage, loading]);

    const callMorePages = async () => {
        if (!nextPage || loading) return;

        setLoading(true);
        try {
            const nextPageData = await callPagination(nextPage);
            setShopProducts((prevProducts) => [...prevProducts, ...nextPageData.data.results]);
            setNextPage(nextPageData.data.next); // Update to the next page if available
        } catch (error) {
            console.error("Error fetching more pages:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleShare = async () => {
        const shareData = {
            title: 'RashailAgro',
            text: 'Hey!, check my shop listing on RashailAgro Bazaar',
            url: window.location.href
        };

        if (navigator.share) {
            try {
                await navigator.share(shareData);
            } catch (err) {
                console.error('Error sharing the product:', err);
            }
        } else {
            toast("Web Share API not supported. Please use the copy link or other options.");
        }
    };

    return (
        <div className="mt-32 bg-gray-50">
            {shopData ? (
                <div className="w-full">
                    <img src={shopData.partner.banner} alt={shopData.partner.banner} />
                    <div className="flex flex-col lg:flex-row sm:flex-row gap-3 my-4 mx-14 items-center">
                        {shopData.partner.image != null ? (
                            <img src={shopData.partner.image} alt="Brand Logo" className='object-contain max-h-12' />
                        ) : <></>}
                        <span className='font-bold text-xl'>
                            {shopData.partner.name}
                        </span>
                    </div>
                    <h1 className='text-center text-3xl my-4'>{t("CheckOurProducts")}</h1>
                    <div className="px-16">
                        <hr className='bg-secondary h-[1px] w-full border-0 mb-2' />
                    </div>
                    <div className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 px-4 md:px-24`}>
                        {shopProducts.map((product) => (
                            <ProductCard key={product.id} product={product} isShopCard={true} />
                        ))}
                        {/* Invisible div to observe for triggering pagination */}
                        <div ref={listEndRef} className="w-full h-1"></div>
                    </div>
                    <div className="mx-4">
                        <CustomerReviews reviews={customerReviews} />
                    </div>
                    <hr className='bg-secondary h-[1px] w-full border-0 my-4' />
                    <div className="flex flex-row justify-center cursor-pointer mt-6 text-2xl" onClick={handleShare}>
                        <svg height={40} width={40} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                            <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" /><path d="M24 7h2v21h-2z" /><path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
                        </svg>
                        {t("shareWithFriends")}
                    </div>
                </div >
            ) : (
                <div className="h-full w-full flex justify-center">
                    <img src={loading} alt="Loading..." className='min-h-40' />
                </div>
            )}
            {loading && (
                <div className="flex justify-center py-4">
                    <LoadingSpinner />
                </div>
            )}
        </div >
    );
};

export default ShopDetailedPage;
