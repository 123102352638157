import React from 'react';

const CustomRowText = ({
    label,
    value,
    labelColor = 'text-gray-700',
    valueColor = 'text-black',
    labelFontWeight = 'font-normal',
    valueFontWeight = 'font-bold'
}) => {
    return (
        <div className="flex justify-between text-sm  md:text-md items-center my-2 w-full">
            <span className={`${labelColor} ${labelFontWeight}`}>
                {label}
            </span>
            <span className={`${valueColor} ${valueFontWeight}`}>
                {value}
            </span>
        </div>
    );
};

export default CustomRowText;
