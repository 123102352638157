import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import languageIcon from '../../assets/Navbar/navbar_language.png';

const LanguageSwitcher = ({ isFromDrawer = false }) => {
    const { i18n, t } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null); // Use ref to track dropdown

    // Available languages
    const availableLanguages = [
        { code: 'en', name: 'English' },
        { code: 'hi', name: 'हिंदी' },
        // { code: 'mr', name: 'मराठी' },
        // { code: 'gu', name: 'ગુજરાતી' },
    ];

    // Function to handle language change
    const changeLanguage = (code) => {
        i18n.changeLanguage(code);
        setIsDropdownOpen(false);
        localStorage.setItem('language', code);
        window.location.reload();
    };

    // Close dropdown if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}> {/* Attach ref here */}
            {/* Language Button */}
            <div
                className={`${isFromDrawer ? "flex" : "hidden"} md:flex lg:flex items-center border border-green-400 text-green-500 px-3 py-2 rounded-full cursor-pointer`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
            >
                <img src={languageIcon} alt="Language" className='h-6' />
                <span className={`ml-1 ${isFromDrawer ? "flex" : "hidden"} lg:flex`}>{t("selectLanguage")}</span>
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
                <div className="absolute bg-white border border-gray-200 rounded-md mt-2 right-0 w-32 shadow-lg z-50">
                    {availableLanguages.map((lang) => (
                        <div
                            key={lang.code}
                            className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-green-100"
                            onClick={() => changeLanguage(lang.code)}
                        >
                            {lang.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;
