import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { bazzarCreateOrderApi, bazzarVerifyPaymentApi } from '../constants/apis';
import { OrderSuccessModal } from "./OrderSuccessModal";

const Checkout = ({ cart, totalPayableAfterDiscount, amountToDisp, mobileNumber, email, selectedAddress, userId, token, paymentMode, resetFunction, showAddAddressPop }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const executeTransactions = async () => {
        if (selectedAddress) {
            setIsLoading(true);
            try {
                const bodyData = {
                    user: userId,
                    total_price: totalPayableAfterDiscount,
                    total_items: cart.length,
                    shipping_address: selectedAddress,
                    products: cart.map((item) => ({
                        product: item.id,
                        quantity: item.quantity,
                    })),
                    payment_method: paymentMode,
                };

                // Step 1: Create Order
                const response = await axios.post(bazzarCreateOrderApi, bodyData, {
                    headers: { Authorization: `Token ${token}` },
                });

                const { razorpay_key_id, amount, order_id } = response.data;

                // Step 2: Set up Razorpay payment options
                const options = {
                    key: razorpay_key_id,
                    amount: amount * 100,
                    order_id: order_id,
                    name: 'RashailAgro',
                    description: 'Payment for Shop Product Purchase',
                    handler: async (response) => {
                        await handlePaymentSuccess(response);
                    },
                    prefill: {
                        contact: mobileNumber,
                        email: email,
                    },
                    theme: {
                        color: '#F37254',
                    },
                    external: {
                        wallets: [
                            'paytm',
                        ]
                    }
                };

                // Open Razorpay
                const razorpay = new window.Razorpay(options);
                razorpay.open();
                razorpay.on('payment.failed', handlePaymentError);
            } catch (error) {
                toast.error('Error initiating payment. Please try again.');
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            showAddAddressPop();
            toast.error('Please select an address.');
        }
    };

    const handlePaymentSuccess = async (response) => {
        const bodyData = {
            order_id: response.razorpay_order_id,
            transaction_id: response.razorpay_payment_id,
            payment_signature: response.razorpay_signature,
        };

        try {
            // Verify Payment
            const verificationResponse = await axios.post(bazzarVerifyPaymentApi, bodyData, {
                headers: { Authorization: `Token ${token}` },
            });

            if (verificationResponse.status == 200) {
                setIsModalOpen(true);
                toast.success('Payment Successful!');
                resetControllers();
            } else {
                toast.error('Payment verification failed. Please contact support.');
            }
        } catch (error) {
            toast.error('Error verifying payment.');
            console.error(error);
        }
    };

    const handlePaymentError = (response) => {
        const bodyData = {
            payment_status: 'Error',
        };
        sendDataToApi(bodyData, false);
    };

    const resetControllers = () => {
        resetFunction();
    };

    const sendDataToApi = async (bodyData, isSuccess) => {
        try {
            await axios.post(bazzarVerifyPaymentApi, bodyData, {
                headers: { Authorization: `Token ${token}` },
            });
            if (isSuccess) {
                resetControllers();
            }
        } catch (error) {
            toast.error('Error updating payment status.');
            console.error(error);
        }
    };

    return (
        <>
            <div>
                <button
                    onClick={executeTransactions}
                    disabled={isLoading}
                    className="text-md md:text-lg bg-primary font-bold text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
                >
                    {isLoading ? 'Processing...' : `Pay ₹${parseInt(amountToDisp)}`}
                </button>
            </div>
            <OrderSuccessModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};

export default Checkout;
