import React, { useEffect, useRef, useState } from 'react';

function ProfileDropdown({ userDetails, logout }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null); // Use ref to track dropdown


    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        setIsDropdownOpen(false); // Close dropdown after logging out
        logout();
    };

    const handleHistory = () => {
        setIsDropdownOpen(false); // Close dropdown after navigating to history
        // Navigate to history page (example with React Router)
        // navigate('/history');
        console.log("Navigating to history...");
    };


    // Close dropdown if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className="relative">
            <div
                className="flex items-center space-x-1 text-primary fill-primary cursor-pointer gap-1 max-w-28"
                onClick={toggleDropdown}
            >
                <img
                    src={userDetails?.userdetails?.image}
                    alt="Profile"
                    className="profile-avatar max-h-10 aspect-square rounded-full"
                />
                <span className="hidden lg:flex truncate">
                    {userDetails?.userdetails?.username}
                </span>
            </div>

            {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg border border-gray-200 z-10"
                    ref={dropdownRef}
                >
                    {/* <button
                        className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        onClick={handleHistory}
                    >
                        History
                    </button> */}
                    <button
                        className="w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100"
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                </div>
            )}
        </div>
    );
}

export default ProfileDropdown;
