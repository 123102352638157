import React, { useEffect, useState } from 'react';
import { getCategoriesAndSubCategories, getDomainCategoryAndSubCategory } from '../services/category';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SubCategoryCard from '../components/SubCategoryCard';
import loading from "../assets/Images/loading.gif";


const ViewAllCategories = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const [categories, setCategories] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRequired = async () => {
            setLoading(true);
            const categs = await getCategoriesAndSubCategories();
            setCategories(categs.data.categories);
            setLoading(false);
        };
        fetchRequired();
    }, []);

    return (
        <div className="mt-32">
            {isLoading ?
                <div className="flex flex-row w-full items-center justify-center text-center">
                    <img src={loading} alt="Loading..." className='min-h-40' />
                </div>
                :
                <>
                    <h1 className='text-center text-xl md:text-3xl my-4 font-bold'>{t("allCategories")}</h1>
                    {categories.map((item) => (
                        <div className='mt-4'>
                            <div key={item.id}>
                                <div className="flex flex-row item-center px-4 md:px-14 gap-4">
                                    <p className="font-bold">
                                        <Link to={`/view/products?utm=category=${item.name}`} className='flex flex-row hover:underline hover:text-primary'>
                                            <div className="h-8 w-8 rounded-full border border-primary mr-4 overflow-hidden">
                                                <img src={item.image} alt={item.name} className='object-cover w-full h-full' />
                                            </div>
                                            <span>
                                                {item.name}
                                            </span>
                                        </Link>
                                    </p>
                                    <div className="flex-1 justify-center text-center items-center flex flex-col">
                                        <hr className='bg-secondary h-[1px] w-full border-0' />
                                    </div>
                                </div>
                                <div className={`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-9 gap-8 ${isTab ? "py-4 px-4" : "px-14 py-4"}`}>
                                    {item.subcategories.map((category) => (
                                        <SubCategoryCard key={category.id} subcategory={category} />
                                    ))}
                                </div>
                            </div>
                        </ div>
                    ))}
                </>
            }
        </div>
    );
};

export default ViewAllCategories;
