import React, { useState, useEffect } from 'react';
import { getCartItems, updateCartQuantity, clearCart } from '../utils/cartUtils';
import emptyCart from "../assets/Navbar/empty_cart.gif";
import CustomRowText from '../components/CustomRowText';
import { useMediaQuery } from '@mui/material';
import { useCart } from '../contexts/CartContext';
import Checkout from '../components/Checkout';
import { userDetailsData } from '../services/user_detail_api';
import AddressComponent from '../components/AddressComponent';
import { getUserToken } from '../utils/loginUtils';
import LoadingSpinner from '../components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import Loginform from '../components/Navbar/Loginform';
import Otplogin from '../components/Navbar/Otplogin';
import Signup from '../components/Navbar/Signup';

const ReviewOrder = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const [cart, setCart] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [selectedAddressId, setSelectedAddressIds] = useState("");
    const [priceDetails, setPriceDetails] = useState({
        itemsCount: 0,
        mrp: 0,
        discount: 0,
        additionalDiscount: 0,
        deliveryCharges: 0,
        totalSavings: 0,
        totalPayable: 0,
        amountToDisplay: 0
    });
    const [paymentSelection, setPaymentSelection] = useState('Prepaid');
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('login');
    const [showAddressAddPop, setShowAddressAddPop] = useState(false);

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setModalType('login');
        setShowModal(true);
    };

    // Handle address selection
    const handleAddressSelect = (id) => {
        setSelectedAddressIds(id);
    };

    // Handle add address
    const handleAddAddress = () => {
        window.location.reload();
    };

    const closeOtp = () => {
        handleCloseModal();
        window.location.reload();
    };

    const renderModalContent = () => {
        switch (modalType) {
            case 'login':
                return <Loginform handleModalClose={handleCloseModal} switchToSignup={() => setModalType('signup')} switchToOtp={() => setModalType('otp')} />;
            case 'otp':
                return <Otplogin handleModalClose={closeOtp} switchToSignup={() => setModalType('signup')} />;
            case 'signup':
                return <Signup handleModalClose={handleCloseModal} switchToLogin={() => setModalType('login')} />;
            default:
                return null;
        }
    };

    // Load cart items when component mounts
    useEffect(() => {
        const token = getUserToken();
        const cartItems = getCartItems();
        setCart(cartItems);
        calculatePriceDetails(cartItems);
        if (token) {
            setLoading(true);
            const fetchRequired = async () => {
                const details = await userDetailsData();
                setUserDetails(details.data);
                setLoading(false);
            };
            fetchRequired();
        } else {
            setLoading(false);
            handleOpenModal();
        }
    }, []);

    // Function to calculate price details based on cart items
    const calculatePriceDetails = (cartItems) => {
        let items = 0;
        let mrp = 0;
        let discount = 0;
        let totalPayable = 0;
        let deliveryCharges = 0;

        cartItems.forEach(item => {
            const itemTotalPrice = item.mrp * item.quantity;
            const itemTotalPay = item.price * item.quantity;
            mrp += itemTotalPrice;
            totalPayable += itemTotalPay;
            items += item.quantity;
            // Calculate discount based on item.discount and discount_percentage
            const itemDiscountPercentage = item.discount_percentage ? item.discount_percentage / 100 : 0;
            const itemDiscount = itemTotalPrice * itemDiscountPercentage; // Calculate discount using percentage
            discount += itemDiscount;
            

            // If there's an additional item.discount (flat), add that too
            discount += item.discount ? item.discount * item.quantity : 0;
        });
        deliveryCharges = totalPayable >= 500 ? 0 : 80;

        // Fix mrp and total payable to two decimal points
        mrp = parseFloat(mrp.toFixed(2));
        const totalSav = discount + (totalPayable * 0.05);

        setPriceDetails({
            itemsCount: items,
            mrp: mrp,
            discount: discount.toFixed(2), // Total discount
            discountPercentage: ((discount / mrp) * 100).toFixed(2), // Calculate overall discount percentage
            additionalDiscount: totalPayable * 0.05, // If you have additional discount logic
            deliveryCharges: deliveryCharges === 0 ? 'FREE' : deliveryCharges,
            totalSavings: totalSav,
            totalPayable: totalPayable - (totalPayable * 0.05),
            amountToDisplay: totalPayable
        });
    };

    const { emptyCartContext } = useCart();

    const handleClearCart = () => {
        clearCart();
        setCart([]);
        // emptyCartContext();
        calculatePriceDetails([]);
    };

    const handlePaymentMode = (mode) => {
        if (paymentSelection !== mode) {
            if (mode === "COD") {
                // Calculate 10% of the amount
                const codPayable = priceDetails.amountToDisplay * 0.1;
                
                // Ensure minimum of 100 rupees for COD
                const finalCodPayable = Math.max(codPayable, 100);
    
                setPriceDetails({
                    ...priceDetails,
                    additionalDiscount: 0,
                    totalSavings: priceDetails.totalSavings - (priceDetails.amountToDisplay * 0.05),
                    totalPayable: finalCodPayable,
                    amountToDisplay: priceDetails.amountToDisplay,
                });
            } else {
                // Prepaid mode
                const prepaidDiscount = priceDetails.amountToDisplay * 0.05;
                
                setPriceDetails({
                    ...priceDetails,
                    additionalDiscount: prepaidDiscount,
                    totalSavings: priceDetails.totalSavings + prepaidDiscount,
                    totalPayable: priceDetails.amountToDisplay - prepaidDiscount,
                    amountToDisplay: priceDetails.amountToDisplay,
                });
            }
            
            // Update payment selection
            setPaymentSelection(mode);
        }
    };

    return (
        <div className="mx-4 md:mx-14 mt-32 mb-14">
            <p className='font-bold text-lg md:text-xl mb-4'>{t("ReviewYourOrder")}</p>
            {cart.length === 0 ? (
                <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-6">
                    {/* Empty Cart Image */}
                    <img
                        src={emptyCart}
                        alt="Empty Cart"
                        className="h-48"
                    />

                    {/* Empty Cart Message */}
                    <h2 className="text-2xl font-semibold text-gray-700">
                        No Products
                    </h2>
                    <p className="text-gray-500 text-center">
                        Looks like you haven't added anything to your cart yet.
                    </p>

                    {/* Shop Now Button */}
                    <button
                        onClick={() => window.location.href = '/'}
                        className="bg-primary text-white px-6 py-3 rounded-md hover:bg-primary-dark transition-colors"
                    >
                        Shop Now
                    </button>
                </div>
            ) : (
                <>
                    <div className="flex lg:flex-row flex-col gap-4">
                        {/* Address section */}
                        {
                            isLoading ?
                                <LoadingSpinner /> :
                                userDetails ?
                                    <AddressComponent
                                        userAddresses={userDetails}
                                        selectedAddressId={selectedAddressId}
                                        handleAddressSelect={handleAddressSelect}
                                        mobileNumber={userDetails.userdetails.mobile_number}
                                        userId={userDetails.userdetails.id}
                                        email={userDetails.userdetails.email}
                                        handleAddAddress={handleAddAddress}
                                        showAddressAddPop={showAddressAddPop}
                                    />
                                    :
                                    <div className="flex flex-col rounded-lg bg-white shadow-lg px-8 py-4 border items-start w-full lg:w-1/3">
                                        <p className="py-2 font-bold text-md md:text-lg">{t("SelectDeliveryAddress")}</p>
                                        <div className="flex flex-col justify-center items-center w-full h-full">
                                            <p className="py-2 font-semibold text-md text-center w-full">{t("loginToContinue")}</p>
                                            <button
                                                onClick={() => handleOpenModal()}
                                                className="bg-primary font-bold text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
                                            >
                                                {t("login")}
                                            </button>
                                        </div>
                                    </div>
                        }

                        {/* Cart Section */}
                        <div className="flex flex-col rounded-lg bg-white shadow-lg px-4 py-4 border w-full lg:w-2/3 md:overflow-y-auto">
                            {cart.map((item, index) => (
                                <div key={item.id} className='flex flex-row mb-4 gap-4 items-start'>
                                    {/* Product Image */}
                                    <img
                                        src={item.product_images && item.product_images.length > 0
                                            ? item.product_images[0].image
                                            : "/default-item.png"}
                                        alt={item.product_images && item.product_images.length > 0
                                            ? item.product_images[0].alt_text
                                            : "img"}
                                        className="min-w-[80px] max-w-[80px] object-cover"
                                    />

                                    {/* Product Info */}
                                    <div className="flex flex-col flex-1 text-sm md:text-md">
                                        {/* Product Name (Allow text to wrap and not overflow) */}
                                        <span className='font-bold break-words line-clamp-3'>
                                            {item.product_name}
                                        </span>

                                        {/* Quantity and Price */}
                                        <span>
                                            <span className='text-slate-600 mr-2 text-xs md:text-sm'>
                                                Quantity:
                                            </span>
                                            <span>
                                                {item.quantity} X ₹ {item.price}
                                            </span>
                                        </span>

                                        {/* Total Price */}
                                        <span className='font-bold text-xs md:text-sm'>
                                            ₹ {item.quantity * item.price}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Price Details Section */}
                        <div className="flex flex-col rounded-lg bg-white shadow-lg px-8 py-4 border items-start w-full lg:w-1/3">
                            <p className="py-2 font-bold text-lg">{t("PriceDetails")}</p>

                            <CustomRowText
                                label={`${t("MRP")} (${priceDetails.itemsCount} Items)`}
                                value={`₹${parseInt(priceDetails.mrp)}`}
                            />

                            <CustomRowText
                                label={t("Discount")}
                                value={`- ₹${parseInt(priceDetails.discount)}`}
                                valueColor="text-green-600"
                            />

                            <CustomRowText
                                label={t("DeliveryCharges")}
                                value={`${priceDetails.deliveryCharges === 'FREE' ? "" : "₹"}${priceDetails.deliveryCharges}`}
                                valueColor={priceDetails.deliveryCharges === 'FREE' ? 'text-green-600' : ''}
                            />

                            <hr className="my-2 border-black w-full border-1 border-dotted" />

                            <CustomRowText
                                label={t("TotalPayable")}
                                value={`₹${parseInt(priceDetails.amountToDisplay + priceDetails.deliveryCharges)}`}
                                labelFontWeight="font-bold"
                                valueFontWeight="font-bold"
                                labelColor="text-black"
                                valueColor="text-green-700"
                            />
                            {paymentSelection === 'Prepaid' ?
                                (
                                    <CustomRowText
                                        label={`${t("AdditionalDiscount")} ${paymentSelection === 'Prepaid' ? '(5%)' : ''}`}
                                        value={`₹${parseInt(priceDetails.additionalDiscount)}`}
                                    />
                                ) : (
                                    <>
                                        <CustomRowText
                                            label={`${t("payNow")} (10%)`}
                                            value={`₹${parseInt(priceDetails.totalPayable)}`}
                                        />
                                        <CustomRowText
                                            label={`${t("payLater")}`}
                                            value={`₹${parseInt(priceDetails.amountToDisplay - priceDetails.totalPayable)}`}
                                        />
                                    </>
                                )}
                            <div className="flex flex-row gap-2 my-2 justify-center w-full">
                                <button
                                    className={`px-2 py-1 rounded-full text-xs md:text-sm text-white ${paymentSelection === 'COD' ? 'bg-yellow-500' : 'bg-gray-400'} hover:bg-gray-500 focus:outline-none transition font-bold`}
                                    onClick={() => handlePaymentMode('COD')}
                                >
                                    {t("COD")}
                                </button>
                                <button
                                    className={`px-2 py-1 rounded-full text-xs md:text-sm text-white ${paymentSelection === 'Prepaid' ? 'bg-primary' : 'bg-gray-400'} hover:bg-gray-500 focus:outline-none transition font-bold`}
                                    onClick={() => handlePaymentMode('Prepaid')}
                                >
                                    {t("Prepaid")}
                                </button>
                            </div>
                            {
                                paymentSelection === "COD" ?
                                    (<p className='text-sm text-primary text-center w-full'>{t("Youhavetopay5atthetimeofbooking")}</p>) : (<></>)
                            }
                            <div className="flex flex-row justify-end my-4 w-full items-center gap-1">
                                <span className='text-primary text-xs md:text-md mr-1'>
                                    {t("youSaved")}:
                                </span>

                                <span className='text-primary text-xs md:text-xl font-bold mr-1'>
                                    {`₹${parseInt(priceDetails.totalSavings)}`}
                                </span>
                                {isLoading ? (<LoadingSpinner />) : (
                                    userDetails ?
                                        <Checkout
                                            cart={cart}
                                            totalPayableAfterDiscount={priceDetails.amountToDisplay}
                                            amountToDisp={priceDetails.totalPayable + priceDetails.deliveryCharges}
                                            paymentMode={paymentSelection}
                                            selectedAddress={selectedAddressId}
                                            mobileNumber={userDetails.userdetails.mobile_number}
                                            token={getUserToken()}
                                            userId={userDetails.userdetails.id}
                                            resetFunction={handleClearCart}
                                            showAddAddressPop={() => setShowAddressAddPop(true)}
                                        /> :
                                        <button
                                            onClick={() => handleOpenModal()}
                                            className="bg-primary font-bold text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
                                        >
                                            {t("login")}
                                        </button>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showModal ? <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                < div className="bg-white rounded-lg">
                    {renderModalContent()}
                </div >
            </div > : <></>}
        </div>
    );
};

export default ReviewOrder;
