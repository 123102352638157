import React, { useState, useEffect } from 'react';

const VisitorCounter = () => {
    const [totalCount, setTotalCount] = useState(0);
    const [dailyCount, setDailyCount] = useState(0);

    useEffect(() => {
        // Initialize GA4 script if it's not already loaded
        if (!document.querySelector('script[src*="googletagmanager"]')) {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-29V1TP7LT2';
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-29V1TP7LT2');

                // After GA4 is loaded, track this pageview
                gtag('event', 'page_view', {
                    page_location: window.location.href,
                    page_title: document.title
                });
            };
        }

        // Function to get today's date in YYYY-MM-DD format
        const getToday = () => {
            const today = new Date();
            return today.toISOString().split('T')[0];
        };

        // Function to get the start of the current session
        const getSessionStart = () => {
            let sessionStart = sessionStorage.getItem('sessionStart');
            if (!sessionStart) {
                sessionStart = new Date().toISOString();
                sessionStorage.setItem('sessionStart', sessionStart);
            }
            return sessionStart;
        };

        // Function to track a visit
        const trackVisit = async () => {
            try {
                // Check if this is a new session
                const lastVisit = localStorage.getItem('lastVisit');
                const today = getToday();
                const currentTime = new Date().getTime();

                // If no last visit or last visit was more than 30 minutes ago
                if (!lastVisit || (currentTime - new Date(lastVisit).getTime()) > 30 * 60 * 1000) {
                    // Update total count
                    const storedTotal = localStorage.getItem('totalVisitorCount');
                    const newTotal = storedTotal ? parseInt(storedTotal) + 1 : 1;
                    localStorage.setItem('totalVisitorCount', newTotal.toString());
                    setTotalCount(newTotal);

                    // Update daily count
                    const storedDailyData = localStorage.getItem('dailyVisitorData');
                    let dailyData = storedDailyData ? JSON.parse(storedDailyData) : {};

                    // Reset daily count if it's a new day
                    if (!dailyData[today]) {
                        dailyData = { [today]: 1 };
                    } else {
                        dailyData[today] += 1;
                    }

                    localStorage.setItem('dailyVisitorData', JSON.stringify(dailyData));
                    setDailyCount(dailyData[today]);

                    // Update last visit timestamp
                    localStorage.setItem('lastVisit', new Date().toISOString());

                    // Send event to GA4
                    if (window.gtag) {
                        window.gtag('event', 'user_visit', {
                            'event_category': 'engagement',
                            'event_label': 'site_visit',
                            'value': 1
                        });
                    }
                } else {
                    // If it's not a new session, just get the current counts
                    const storedTotal = localStorage.getItem('totalVisitorCount');
                    const storedDailyData = localStorage.getItem('dailyVisitorData');
                    const dailyData = storedDailyData ? JSON.parse(storedDailyData) : {};

                    setTotalCount(parseInt(storedTotal) || 0);
                    setDailyCount(dailyData[today] || 0);
                }
            } catch (error) {
                console.error('Error tracking visit:', error);
            }
        };

        // Track initial visit
        trackVisit();

        // Set up tracking for visibility changes
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                trackVisit();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Clean up
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div className="w-full flex justify-center items-center text-white bg-black py-4">
            <div className="flex flex-row gap-4 max-w-md justify-evenly w-full">
                <div className="flex flex-col w-full flex-1 items-center">
                    <div className="text-xl font-bold">{totalCount.toLocaleString()}</div>
                    <p className="text-xs">all time visitors</p>
                </div>
                <div className="flex flex-col w-full flex-1 items-center">
                    <div className="text-xl font-bold">{dailyCount.toLocaleString()}</div>
                    <p className="text-xs">
                        visitors today
                    </p>
                </div>
            </div>
        </div>
    );
};

export default VisitorCounter;