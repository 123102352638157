import React, { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { getUtmProducts } from '../services/product';
import { addToCart } from '../utils/cartUtils';
import ProductCard from '../components/ProductCard';
import { callPagination } from '../services/pagination';
import PartnerCard from '../components/PartnerCard';
import SupplierCard from '../components/SupplierCard';
import { getSellers } from '../services/manufacturer';
import { getCurrentPosition } from 'geolocation';
import { useTranslation } from 'react-i18next';

const ViewAllSuppliers = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const [nearbySellers, setNearbySellers] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const listInnerRef = useRef();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchRequired = async () => {

            getCurrentPosition(async function (err, position) {
                if (err) throw console.log("Error in fetching location", err);
                const sellers = await getSellers(position.coords.latitude, position.coords.longitude);
                setNearbySellers(sellers.data.results);
                setNextPage(sellers.data.next);
            });
        };
        fetchRequired();
    }, []);

    // Infinite scrolling logic
    useEffect(() => {
        if (!listInnerRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && nextPage) {
                    callMorePages();
                }
            },
            { threshold: 1 }
        );

        observer.observe(listInnerRef.current);

        return () => observer.disconnect();
    }, [nextPage]);

    const callMorePages = async () => {
        if (!nextPage || loading) return; // Prevent multiple calls if no next page or already loading

        setLoading(true); // Set loading to true before making the API call
        try {
            const nextPageData = await callPagination(nextPage);
            setNearbySellers((prevProducts) => [...prevProducts, ...nextPageData.data.results]);
            setNextPage(nextPageData.data.next); // Update the next page URL
        } catch (error) {
            console.error("Error fetching more pages: ", error);
        } finally {
            setLoading(false); // Reset loading state after API call completes
        }
    };

    const handleAddToCart = (product) => {
        addToCart(product);
    };

    // Infinite scrolling logic with throttling
    const handleScroll = () => {
        if (loading || !nextPage) return;

        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollY + windowHeight >= documentHeight - 100) {
            callMorePages();
        }
    };


    useEffect(() => {
        let timeout;
        const handleThrottledScroll = () => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(handleScroll, 200);
        };

        window.addEventListener('scroll', handleThrottledScroll);
        return () => {
            window.removeEventListener('scroll', handleThrottledScroll);
            clearTimeout(timeout);
        };
    }, [nextPage, loading]);



    return (
        <div className="mt-32">
            <div className="py-14 bg-gradient-to-r from-primary to-green-400 text-white flex justify-center">
                <h1 className="text-4xl font-extrabold mb-4">{t("nearbySuppliers")}</h1>
            </div>
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ${isTab ? "py-8 px-4" : "py-8 px-14"}`}>
                {nearbySellers.map((partner) => (
                    <SupplierCard partner={partner} />
                ))}
            </div>
        </div>
    );
};

export default ViewAllSuppliers;
