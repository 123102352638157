import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppLogo from '../constants/image_constants';
import Loginform from './Navbar/Loginform';
import Otplogin from './Navbar/Otplogin';
import Signup from './Navbar/Signup';
import { SwipeableDrawer } from '@mui/material';
import { useCart } from '../contexts/CartContext';
import { getUserToken } from '../utils/loginUtils';
import { getDomainCategoryAndSubCategory } from '../services/category';
import Collapsible from 'react-collapsible';
import SearchBar from './SearchBar';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './Navbar/LanguageSwitcher';
import { userDetailsData } from '../services/user_detail_api';
import { partnerForm } from '../constants/apis';
import ProfileDropdown from './Navbar/ProfileDropdown';

const Navbar = () => {
    const location = useLocation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [modalType, setModalType] = useState('login');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { cartItemCount } = useCart();
    const [userToken, setUserToken] = useState("");
    const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownRef = useRef(null); // Use ref to track dropdown
    const [userDetails, setUserDetails] = useState({});
    const [tempMobile, setTempMobile] = useState(null);
    const [isOpenedViaApp, setIsOpenevViaApp] = useState(false);



    const renderModalContent = () => {
        switch (modalType) {
            case 'login':
                return <Loginform
                    handleModalClose={handleCloseModal}
                    switchToSignup={(mob) => {
                        setModalType('signup');
                        setTempMobile(mob);
                    }}
                    switchToOtp={() => setModalType('otp')}
                />;
            case 'otp':
                return <Otplogin handleModalClose={closeOtp} switchToSignup={() => setModalType('signup')} />;
            case 'signup':
                return <Signup handleModalClose={handleCloseModal} switchToLogin={() => setModalType('login')} switchToOtp={() => setModalType('otp')} prefilledNumber={tempMobile} />;
            default:
                return null;
        }
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setModalType('login');
        setShowModal(true);
    };

    // Function to toggle drawer state
    const toggleDrawer = (open) => (event) => {
        setIsDrawerOpen(open);

        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    const closeOtp = () => {
        handleCloseModal();
        window.location.reload();
    };

    useEffect(() => {
        checkUser();
        const fetchRequired = async () => {
            const categs = await getDomainCategoryAndSubCategory();
            setCategories(categs.data);

            if (getUserToken().length !== 0) {
                const ud = await userDetailsData();
                setUserDetails(ud.data);
            } else{
                handleOpenModal();
            }
        };
        fetchRequired();
    }, [location.pathname]);

    const checkUser = async () => {
        setUserToken(getUserToken());
    };

    // Handle logout confirmation
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLogoutPopupOpen(false);
        window.location.reload();
    };

    // Close dropdown if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActiveDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLinkClick = () => {
        setIsDrawerOpen(false);
    };

    useEffect(
        () => {
            const searchParam = window.location.search;
            setIsOpenevViaApp(searchParam.includes("token"));
        }, []);
    // Drawer content
    const drawerContent = (
        <div role="presentation" onKeyDown={toggleDrawer(false)} className='h-full px-4 w-full'>
            <div className='flex flex-col w-full h-full py-10 gap-2'>
                <LanguageSwitcher isFromDrawer={true} />
                <Link to="/cart" onClick={handleLinkClick} className="text-gray-700 hover:text-gray-900 font-semibold">
                    {/* Cart Icon with Badge */}
                </Link>
                <div className="flex items-center space-x-1 relative w-full justify-end font-semibold mb-3">{t("categories")}</div>
                <div className="flex flex-col overflow-auto pr-2">
                    {Object.keys(categories).map((item) => (
                        <Collapsible trigger={`${item} -`} key={item} className='text-md space-y-3 text-end font-semibold' openedClassName='text-md space-y-3 text-end font-bold'>
                            {Object.entries(categories[item]).map(([category, subItems]) => (
                                <div key={category} className="space-y-4">
                                    <p className="text-primary font-normal text-end mb-2">
                                        <Link to={`/view/products?utm=category=${category}`} onClick={handleLinkClick}>
                                            {category}
                                        </Link>
                                        <ul className="text-black mt-1 mb-2">
                                            {subItems.map((subItem) => (
                                                <li key={subItem} className='mb-2'>
                                                    <Link to={`/view/products?utm=subcategory=${subItem}`} onClick={handleLinkClick}>
                                                        {subItem}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </p>
                                </div>
                            ))}
                        </Collapsible>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        <>
            <nav className="bg-white pb-2 shadow-md fixed top-0 left-0 w-full z-50">
                {
                    !isOpenedViaApp ?
                        <div className="flex flex-row justify-end bg-primary px-4 py-1">
                            <ul className='flex flex-row justify-end gap-2 items-center'>
                                <li className="text-white text-[0.5rem] sm:text-sm"><Link className='footerlinkheading' target='_blank' to="https://rashailagro.in/">Corporate Site</Link></li>
                                <li className="text-white text-xs sm:text-sm font-bold">|</li>
                                <li className={`text-white text-[0.5rem] sm:text-sm font-bold`}><Link className='footerlinkheading' to="/vendor-registration/" target='_blank'>{t("becomeAPartner")}</Link></li>
                                <li className="text-white text-xs sm:text-sm font-bold">|</li>
                                <li className={`text-white text-[0.5rem] sm:text-xs bg-red-700 rounded-lg px-4 py-0.5`}><a className='footerlinkheading flex flex-row' href="tel:+918349503619" alt="rashail-agro-mobilenumber" style={{ color: 'white', textDecoration: "none" }}>Call us<span className='hidden sm:flex'>: +91 83495 03619</ span></a></li>
                            </ul>
                        </div> : <div className='flex flex-row justify-end bg-white py-3'></div>
                }
                <div className="mx-auto flex flex-row justify-between items-center md:px-4">
                    {/* Left Section: Logo */}
                    <Link to={"/"}>
                        <AppLogo />
                    </Link>

                    {/* Middle Section: Search bar and language dropdown */}
                    <div className="items-center space-x-4 hidden md:flex">
                        {/* Search Bar */}
                        <div className="min-w-96">
                            <SearchBar />
                        </div>

                        {/* Language Selector */}
                        <LanguageSwitcher />
                    </div>

                    {/* Right Section: Login and Cart */}
                    <div className="flex items-center space-x-6 mr-4">
                        {userToken.length !== 0 ? <>
                            {
                                !isOpenedViaApp ?
                                    <ProfileDropdown userDetails={userDetails} logout={() => setIsLogoutPopupOpen(true)} /> : <></>
                            }
                            {/* <div className="flex items-center space-x-1 text-primary fill-primary cursor-pointer gap-1 max-w-28" onClick={() => setIsLogoutPopupOpen(true)}>
                                <img src={userDetails?.userdetails?.image} alt="Profile" className="profile-avatar max-h-10 aspect-square rounded-full" />
                                <span className="hidden lg:flex truncate">{userDetails?.userdetails?.username}</span>
                            </div> */}
                        </> :
                            <button onClick={() => handleOpenModal()} className="text-gray-700 hover:text-gray-900">
                                <div className="flex items-center space-x-1">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2154 14.4934C16.0821 14.4801 15.9221 14.4801 15.7754 14.4934C12.602 14.3867 10.082 11.7867 10.082 8.58675C10.082 5.32008 12.722 2.66675 16.0021 2.66675C19.2688 2.66675 21.9221 5.32008 21.9221 8.58675C21.9087 11.7867 19.3887 14.3867 16.2154 14.4934Z" stroke="#043A3A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.545 19.4133C6.31833 21.5733 6.31833 25.0933 9.545 27.2399C13.2117 29.6933 19.225 29.6933 22.8917 27.2399C26.1184 25.0799 26.1184 21.5599 22.8917 19.4133C19.2384 16.9733 13.225 16.9733 9.545 19.4133Z" stroke="#043A3A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='hidden lg:flex'>
                                        {t("login")}
                                    </span>
                                </div>
                            </button>
                        }
                        <Link to="/cart" className="text-gray-700 hover:text-gray-900 flex">
                            <div className="flex items-center space-x-1 relative" onClick={() => setTimeout(() => {
                                window.location.reload();
                            }, 1000)}>
                                <div className="relative mr-2">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 24C11.1046 24 12 24.8955 12 26C12 27.1045 11.1046 28 10 28C8.89543 28 8 27.1045 8 26C8 24.8955 8.89543 24 10 24Z" stroke="#043A3A" strokeWidth="2" />
                                        <path d="M22 24.0001C23.1045 24.0001 24 24.8955 24 26.0001C24 27.1047 23.1045 28.0001 22 28.0001C20.8955 28.0001 20 27.1047 20 26.0001C20 24.8955 20.8955 24.0001 22 24.0001Z" stroke="#043A3A" strokeWidth="2" />
                                        <path d="M2.66797 4L3.01625 4.12245C4.7517 4.7326 5.61944 5.03768 6.11576 5.76397C6.61208 6.49028 6.61208 7.45501 6.61208 9.38448V13.0133C6.61208 16.9355 6.6964 18.2297 7.8516 19.4483C9.0068 20.6667 10.8661 20.6667 14.5846 20.6667H16.0013M21.6552 20.6667C23.7365 20.6667 24.7772 20.6667 25.5128 20.0672C26.2484 19.4677 26.4585 18.4485 26.8786 16.41L27.545 13.177C28.0078 10.8583 28.2392 9.69889 27.6473 8.92944C27.0554 8.16 25.033 8.16 22.7864 8.16H14.6993M6.61208 8.16H9.33464" stroke="#043A3A" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                    {cartItemCount > 0 && (
                                        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1.5 py-0.5 transform translate-x-1/2 -translate-y-1/2">
                                            {cartItemCount}
                                        </span>
                                    )}
                                </div>
                                <span className='hidden lg:flex'>
                                    {t("cart")}
                                </span>
                            </div>
                        </Link>

                        {/* Mobile Menu Icon */}
                        {isMobile && (
                            <button
                                onClick={toggleDrawer(true)}
                                className="focus:outline-none text-black"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="black"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
                <div className="mx-auto flex flex-col items-center px-4">
                    {/* Main Menu */}
                    <div className="w-full">
                        <div className="hidden md:flex w-full flex-row justify-center gap-2 items-center">
                            {Object.keys(categories).map((item) => (
                                <div
                                    key={item}
                                    className="group"
                                >
                                    <button
                                        className={`px-3 py-2 text-sm font-medium ${activeDropdown === item ? 'text-green-500' : 'text-gray-700'
                                            } hover:text-green-500 transition-colors truncate`}
                                        onMouseEnter={() => setActiveDropdown(item)}
                                    >
                                        <Link to={`/view/products?utm=domain=${item}`} onClick={() => setActiveDropdown(null)}>
                                            {item}
                                        </Link>
                                    </button>

                                    {/* Dropdown Panel */}
                                    {activeDropdown === item && Object.keys(categories[item]).length > 0 && (
                                        <div
                                            className="fixed left-0 right-0 mt-10 bg-white shadow-lg border-t"
                                            style={{ top: '64px' }}
                                            onMouseLeave={() => setActiveDropdown(null)}
                                            ref={dropdownRef}
                                        >
                                            {/* Semi-transparent overlay to handle mouse movement gap */}
                                            <div
                                                className="absolute h-4 -top-4 left-0 right-0"
                                                onMouseEnter={(e) => e.stopPropagation()}
                                            />

                                            <div className="container mx-auto px-4 py-6">
                                                <div className="grid grid-cols-6 gap-4">
                                                    {Object.entries(categories[item]).map(([category, subItems]) => (
                                                        <div key={category} className="space-y-1">
                                                            <h3 className="text-green-500 font-bold">
                                                                <Link to={`/view/products?utm=category=${category}`} onClick={() => setActiveDropdown(null)}>
                                                                    {category}
                                                                </Link>
                                                            </h3>
                                                            <ul>
                                                                {subItems.map((subItem) => (
                                                                    <li key={subItem} className='hover:underline'>
                                                                        <Link to={`/view/products?utm=subcategory=${subItem}`} onClick={() => setActiveDropdown(null)}>
                                                                            {subItem}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-screen mt-2 md:hidden px-4">
                        <SearchBar />
                    </div>
                </div>
            </nav >

            {/* Side Drawer */}
            <SwipeableDrawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {drawerContent}
            </SwipeableDrawer >
            {
                showModal ? <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    < div className="bg-white rounded-lg">
                        {renderModalContent()}
                    </div >
                </div > : <></>
            }


            {/* Logout confirmation popup */}
            {isLogoutPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <p className="mb-4">{t("areYouSureToLogout")}</p>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                                onClick={handleLogout}
                            >
                                {t("yesLogout")}
                            </button>
                            <button
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                                onClick={() => setIsLogoutPopupOpen(false)}
                            >
                                {t("cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default Navbar;
