import React, { useState } from 'react';
import './ImageZoom.css';

const ImageZoom = ({ src, className = "" }) => {
    const [zoomStyle, setZoomStyle] = useState({ display: 'none' });

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;

        setZoomStyle({
            display: 'block',
            backgroundPosition: `${x}% ${y}%`
        });
    };

    const handleMouseLeave = () => {
        setZoomStyle({ display: 'none' });
    };

    return (
        <div className="image-zoom-container">
            <img
                src={src}
                alt="Product"
                className={`main-image ${className}`}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            />
            <div
                className="zoomed-image rounded-lg"
                style={{
                    ...zoomStyle,
                    backgroundImage: `url(${src})`
                }}
            />
        </div>
    );
};

export default ImageZoom;
