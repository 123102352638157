import axios from "axios";
import { bazzarManufacturersApi, bazzarManufacturersGetByNameApi, bazzarShopsListApi } from "../constants/apis";
import { getUserToken } from "../utils/loginUtils";
import i18n from '../i18n';

export const getBrands = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${bazzarManufacturersApi}?lang=${selectedLanguage}`, config);
    return response;
};

export const getBrandDetails = async (slug) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${bazzarManufacturersGetByNameApi}${slug}/?lang=${selectedLanguage}`, config);
    return response;
};

export const getSellers = async (lat, lon, slug = "") => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${bazzarShopsListApi}?lang=${selectedLanguage}&lat=${lat}&lon=${lon}&slug=${slug}`, config);
    return response;
};
