import React, { createContext, useState, useContext, useEffect } from 'react';
import { addToCart, clearCart, getCartItems, removeFromCart } from '../utils/cartUtils';

// Create the Cart Context
const CartContext = createContext();

// Provider component
export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const cartItems = getCartItems();
        setCartItems(cartItems);
    }, []);

    const addToCartContext = (item) => {
        setCartItems((prevItems) => [...prevItems, item]);
        addToCart(item);
    };

    const removeFromCartContext = (itemId) => {
        removeFromCart(itemId);
        const cartItems = getCartItems();
        setCartItems(cartItems);
    };

    const emptyCartContext = () => {
        setCartItems([]);
        clearCart();
    };

    const cartItemCount = cartItems.length;

    return (
        <CartContext.Provider value={{ cartItems, addToCart: addToCartContext, removeFromCart: removeFromCartContext, cartItemCount, emptyCart: emptyCartContext }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook for using cart context
export const useCart = () => {
    return useContext(CartContext);
};
