import { Link } from 'react-router-dom';
import logo from "../assets/Navbar/navbarshop_logo.png";


const PartnerCard = ({ partner }) => {

    return (
        <div className={`bg-white rounded-lg p-2 border border-gray-200 relative min-w-[10rem] md:min-w-[12rem] h-32`} style={{
            boxShadow: '3px 4px 8px rgba(0, 0, 0, 0.3)'
        }}>
            <Link to={`/shop/partners/${partner.slug}`} className={`w-[12rem] h-32 object-contain`}>
                <div className="relative flex flex-row items-center justify-center text-center h-32">
                    {partner.image !== null ? (
                            <img src={partner.image} alt="Brand Logo" className="object-contain w-[12rem] max-h-20" />
                    ) : (
                        <p className='text-primary font-bold text-3xl italic'>{partner.name}</p>
                    )}
                </div>
            </Link>
        </div>
    );
};

export default PartnerCard;
